import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import ButtonIcon from "../../general/buttons/ButtonIcon/ButtonIcon";
import IconFont from "../../general/icons/IconFont/IconFont";
import styles from "./InfoList.module.css";

interface Props {
    name: string;
    linkTo: string;
}

const InfoList: FC<Props> = ({children, name, linkTo}) => {
    const navigate = useNavigate();

    const clickButtonLinkHandler = () => {
        navigate(linkTo)
    }

    return (
        <div className={styles.infoListBlock}>
            <div className={styles.nameBlock}>
                <span>{name}</span>
                <ButtonIcon 
                    handleClick={clickButtonLinkHandler} 
                    icon={
                        <IconFont 
                            iconClass="icon-edit" 
                            fontSize={10} 
                            color="#A6AEC5" 
                        />
                    } 
                />
            </div>
            <ul className={styles.infoList}>
                {children}
            </ul>
        </div>
    )
}

export default InfoList;