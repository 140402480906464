import React, { FC, SetStateAction, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "../../../store/hooks/dispatch.hook";
import { useAppSelector } from "../../../store/hooks/selector.hook";
import { setFonVisiable } from "../../../store/state/all/all.slice";
import Logo from "../../general/icons/Logo/Logo";
import LogoName from "../../general/icons/LogoName/LogoName";
import Sidebar from "../../navigation/sidebar/Sidebar";
import SidebarItem from "../../navigation/sidebar/SidebarItem/SidebarItem";
import IconFont from "../../general/icons/IconFont/IconFont";
import useDefaultSettings from "../../../utl/hooks/useDefaultSettings";

interface Props {
    toggleSidebar: boolean;
    setToggleSidebar: (value: SetStateAction<boolean>) => void
}

const SidebarContainer: FC<Props> = ({ toggleSidebar, setToggleSidebar }) => {
    const dispatch = useAppDispatch()
    const setDefaultSettings = useDefaultSettings();

    const roleId = useAppSelector(state => state.user.roleId);

    let location = useLocation();
    const locations = location.pathname.split("/");
    locations.shift();

    const toggleSidebarHandler = () => {
        setToggleSidebar(prev => {
            dispatch(setFonVisiable({ visiable: false }));
            setDefaultSettings();
            return !prev;
        });
    }

    const defaultHandler = () => {
        dispatch(setFonVisiable({ visiable: false }));
        setToggleSidebar(false);
        setDefaultSettings();
    }

    return (
        <Sidebar
            toggle={toggleSidebar}
            setToggle={toggleSidebarHandler}
            logo={<Logo />}
            logoOpacity={<LogoName />}
        >
            <>{roleId === 1 && <SidebarItem
                active={locations.length > 0 && locations[0].localeCompare("vendors") === 0}
                icon={<IconFont iconClass="icon-groups" />}
                visiableAll={toggleSidebar} name="Вендори" link="vendors"
                handleDefault={defaultHandler}
            />}</>
            <>{roleId && roleId > 1 &&
                <>
                    <SidebarItem
                        active={locations.length > 0 && locations[0].localeCompare("") === 0}
                        icon={<IconFont iconClass="icon-home" />}
                        visiableAll={toggleSidebar} name="Дашборд" link="/"
                        handleDefault={defaultHandler}
                    />
                    <SidebarItem
                        active={locations.length > 0 && locations[0].localeCompare("finance") === 0}
                        icon={<IconFont iconClass="icon-money" />}
                        visiableAll={toggleSidebar} name="Каса" link="finance"
                        handleDefault={defaultHandler}
                    />
                    <SidebarItem
                        active={locations.length > 0 && locations[0].localeCompare("groups") === 0}
                        icon={<IconFont iconClass="icon-groups" />}
                        visiableAll={toggleSidebar} name="Групи" link="groups"
                        handleDefault={defaultHandler}
                    />
                    <SidebarItem
                        active={locations.length > 0 && locations[0].localeCompare("services") === 0}
                        icon={<IconFont iconClass="icon-star-empty" />}
                        visiableAll={toggleSidebar} name="Послуги" link="services/basics"
                        handleDefault={defaultHandler}
                    />
                    <SidebarItem
                        active={locations.length > 0 && locations[0].localeCompare("clients") === 0}
                        icon={<IconFont iconClass="icon-clients" />}
                        visiableAll={toggleSidebar} name="Клієнти" link="clients"
                        handleDefault={defaultHandler}
                    />
                    <SidebarItem
                        active={locations.length > 0 && locations[0].localeCompare("employees") === 0}
                        icon={<IconFont iconClass="icon-workers" />}
                        visiableAll={toggleSidebar} name="Працівники" link="employees"
                        handleDefault={defaultHandler}
                    />
                    <SidebarItem
                        active={locations.length > 0 && locations[0].localeCompare("settings") === 0}
                        icon={<IconFont iconClass="icon-settings" />}
                        visiableAll={toggleSidebar} name="Налаштування" link="settings/locations"
                        handleDefault={defaultHandler}
                    />
                </>
            }</>
        </Sidebar>
    )
}

export default React.memo(SidebarContainer);