import React from 'react';
import Screens from './screens/Screens';

function App() {
  
  return (
      <Screens />
  );
}

export default App;
