import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import AllReducer from './state/all/all.slice';
import EmployeesReducer from './state/employees/employees.slice';
import VendorsReducer from './state/vendors/vendors.slice';
import UserReducer from './state/user/user.slice';
import LocationsReducer from './state/locations/locations.slice';
import ClientsReducer from './state/clients/clients.slice';
import MoneyReducer from './state/money/money.slice';
import ConfigReducer from './state/configuration/configuration.slice';
import ServiceReducer from './state/services/service/service.slice';
import UpsellsReducer from './state/services/upsells/upsells.slice';
import financeReducer from './state/finance/finance.slice';
import groupsReducer from './state/groups/groups.slice';
import createSagaMiddleware from 'redux-saga'
import rootSaga from './state/rootSaga';
import dashdoardReducer from './state/dashboard/dashboard.slice';
import notificationsReducer from "./state/notifications/notifications.slice";

const sagaMiddleware = createSagaMiddleware()
const middleware = [...getDefaultMiddleware({ thunk: false, serializableCheck: false }), sagaMiddleware];

export const store = configureStore({
  reducer: {
    all: AllReducer,
    employees: EmployeesReducer,
    vendors: VendorsReducer,
    user: UserReducer,
    locations: LocationsReducer,
    clients: ClientsReducer,
    money: MoneyReducer,
    config: ConfigReducer,
    services: ServiceReducer,
    upsellsServices: UpsellsReducer,
    finance: financeReducer,
    groups: groupsReducer,
    dashdoard: dashdoardReducer,
    notifications: notificationsReducer,
  },
  middleware
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;